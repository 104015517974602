import React, { useState, useContext, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import AuthContext from '../context/AuthProvider';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import Container from '../components/atoms/Container/Container';
import NewCustomer from '../components/molecules/NewCustomer/NewCustomer';
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import FormInputField from '../components/atoms/FormInputField/FormInputField';
import { validateEmail } from '../helpers/general';
import Button from '../components/atoms/Button/Button';
import Dialog from '../components/atoms/Dialog/Dialog';
import { getStorage, handleLink } from '../helpers/general';

import * as styles from './login.module.css';

const validForm = data => {
  if (!data.email) {
    return 'Email is required.';
  }
  if (!validateEmail(data.email)) {
    return 'Invalid email address.';
  }
};

const ForgotPasswordPage = () => {
  const auth = useContext(AuthContext);
  const handleForgotPassword = auth && auth.forgotPassword;
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const userChecked = auth && auth.state.userChecked;
  const userEmail = getStorage('_customEmail');
  const [fields, setFields] = useState({});
  const [init, setInit] = useState({});

  const [msg, setMessage] = useState({
    error: '',
    success: '',
    dialogSuccess: '',
    dialogError: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(handleLink(`/account/`));
    }

    if (!init && userEmail) {
      setFields({ ...fields, ...{ email: userEmail } });
    }
    setInit(true);
  }, [isLoggedIn, userEmail, fields, init]);

  const clearDialog = () => {
    setDialogOpen(false);
    navigate(handleLink(`/login/`));
  };

  const [values, setValues] = useState({
    email: ''
  });

  const attemptForgotPassword = (e) => {
    e.preventDefault();
    const validationMsg = validForm(values);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    handleForgotPassword(values.email).then(result => {
      // This returns true regardless as we don't confidently get anything back from BC yet.
      if (result) {
        setMessage({
          dialogSuccess:
            'If your email address exists in the system, you will receive an email with instructions to reset your password.'
        });
        setDialogOpen(true);
      } else {
        setMessage({
          dialogError: 'Sorry, something went wrong. Try again later.'
        });
        setDialogOpen(true);
      }
    });
  };

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  if (!isLoggedIn && userChecked) {
    return (
      <Layout>
        <Seo title="Reset Password" />
        {/* <PageBanner
          breadcrumb="Reset Password"
          title="Reset Password"
          color="latte"
          imageSize="medium"
        >
          <p>
            Fill in your email below to request a new password. An email will be
            sent to the address below containing a link to verify your email
            address.
          </p>
        </PageBanner> */}
        
        <Container size="large">
          <div className={styles.gridMargin}>
            <div className={`${styles.loginForm}`}>
              <div>
                  <PageTitle title="Forgot Your Password?" />
                  <p>If you've forgotten your password, enter your email address below and follow the reset instructions</p>
              </div>
              <form onSubmit={(e) => attemptForgotPassword(e)}>
                  <FormInputField
                    id="email"
                    type="input"
                    placeholder="Email Address"
                    value={values['email']}
                    handleChange={onHandleChange}
                  />
                  {msg.error && <p className={styles.errorMessage}>{msg.error}</p>}
                  {/* <div className="formField">
                      
                      <input type="email" name="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="formField">
                      
                      <input type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                  </div> */}

                  <div className={`${styles.formActions}`}>
                      <Button level="primary" type="buttonSubmit">Send</Button>
                      <Link className={styles.backToLoginLink} to={handleLink("/login/")}>Back to login</Link>
                      {/* <Dialog open={dialogMessage ? true : false} 
                          title="Login unsuccessful" 
                          size="sm" 
                          hideBtnCancel 
                          disableBackdropClick
                          onCancel={clearDialog} 
                          onOk={clearDialog}>
                          {dialogMessage}
                      </Dialog> */}
                      {/* <Link to="/forgot-password/">Forgot your password?</Link> */}
                  </div>
              </form>
            </div>
            <NewCustomer />
          </div>
          {/* <PageTitle title="Reset Password" /> */}
          {/* <p>
            Fill in your email below to request a new password. An email will be
            sent to the address below containing a link to verify your email
            address.
          </p> */}
          {/* <div className="grid grid-100">
            <FormInputField
              id="email"
              type="input"
              labelName="Email Address"
              value={values['email']}
              handleChange={onHandleChange}
            />
          </div>
          {msg.error && <p>{msg.error}</p>}

          <div className={`row`}>
            <Button
              type="span"
              level="primary"
              fullWidth={true}
              onClick={() => attemptForgotPassword()}
            >
              Reset Password
            </Button>
          </div> */}

          <Dialog
            open={dialogOpen}
            title="Reset request made"
            size="sm"
            hideBtnCancel
            disableBackdropClick
            onOk={() => clearDialog()}
          >
            {msg.dialogSuccess && (
              <p>{msg.dialogSuccess}</p>
            )}
            {msg.dialogError && (
              <p>{msg.dialogError}</p>
            )}
          </Dialog>
        </Container>
      </Layout>
    );
  } else return null
};

export default ForgotPasswordPage;
