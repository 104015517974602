import React from 'react'

import * as styles from './PageTitle.module.css'

const PageTitle = ({ children, title }) => {
  return (
    <div data-pagetitle className={styles.root}>
        {/* <Container> */}
            <h1 className={`${styles.pageTitle} h6`}>{title}</h1>
            {children && (
                <div className={styles.subtitle}>{children}</div>
            )}
        {/* </Container> */}
    </div>
  )
}

export default PageTitle
