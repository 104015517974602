import React from 'react';

import * as styles from './NewCustomer.module.css';
// import PageTitle from '../../atoms/PageTitle/PageTitle';
import Button from '../../atoms/Button/Button';
import { handleLink } from '../../../helpers/general';
import GlobalContent from '../../atoms/GlobalContent/GlobalContent';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';

const NewCustomer = () => {

  return (
    <div className={styles.createAccount}>
        <div className={styles.content}>
            <GlobalContent name={`New Customer - Content`}></GlobalContent>
        </div>
        {/* <PageTitle title="New Customer?" />
        <p className={styles.subHeader}>
            Create an account with us and you'll be able to:
        </p>
        <ul className={`${styles.createAccountBullets} bullets`}>
            <li>Check out faster</li>
            <li>Save multiple shipping addresses</li>
            <li>Access your order history</li>
            <li>Track new orders</li>
            <li>Save items to your wishlist</li>
        </ul> */}
        <div className={`${styles.formActions}`}>
            <Button fullWidth href={handleLink("/signup/")} level="primary">
                <ContentfulTranslationText keyName="createAccount">Create an account</ContentfulTranslationText>
            </Button>
        </div>
    </div>
  );

};

export default NewCustomer;
