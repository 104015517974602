import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContentfulRichText from '../ContentfulRichText/ContentfulRichText';

function GlobalContent({ name, fallback }) {
    const contentData = useStaticQuery(graphql`
        query {
            allContentfulGlobalContent {
                nodes {
                  name
                  title
                  content {
                      raw
                  }
                }
            }
        }
    `)
    const contentBlocks = contentData?.allContentfulGlobalContent.nodes;

    const contentObj = contentBlocks.find(content => content.name === name);

    if (contentObj) {
        return (
            <>
                {contentObj.title && (<h3>{contentObj.title}</h3>)}
                <ContentfulRichText raw={contentObj.content.raw} />
            </>
        )
    }

    if (fallback) {
        return <GlobalContent name={fallback} />
    }

    return null;
}

export default GlobalContent